import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import LogoQoin from "../assets/brand_guidelines_qoin_final_17@2x.png";
import "./EmailResponse.css";
import AlreadyActivatedImage from "../assets/success.svg";
import SuccessImage from "../assets/success_activation_logo.svg";

function SudahAktivasi() {
  return (
    <>
      <img
        src={AlreadyActivatedImage}
        className="confirmed-image"
        alt="already-confirmed"
      />
      <div className="heading heading-1">AKUN SUDAH DIAKTIVASI</div>
      <div className="heading heading-3">
        Akun kamu sudah pernah diaktivasi. kamu dapat menggunakan semua fitur
        yang ada di Qoin Merchant Administration Portal (MAP).
      </div>
    </>
  );
}

function SuksesAktivasi({ data }) {
  return (
    <>
      <img
        src={SuccessImage}
        className="confirmed-image"
        alt="has-been-confirmed"
      />
      <div className="heading heading-1">AKUN SUKSES DIAKTIVASI</div>
      <div className="heading heading-2">Selamat {data.Fullname}</div>
      <div className="heading heading-3">
        Akun kamu sudah sukses diaktivasi. sekarang kamu dapat menggunakan semua
        fitur yang berada di Qoin Merchant Administration Portal (MAP).
      </div>
    </>
  );
}

function EmailResponse(props) {
  const [responseData, setResponseData] = useState("");
  const values =
    props.location.search && queryString.parse(props.location.search);

  useEffect(() => {
    const validate = async () => {
      let formdata = new FormData();
      formdata.append("EmailKey", values.key);
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}client-manager-email-validate`,
          formdata,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => res)
        .catch((err) => err.response);
      if (response.data.status) setResponseData(response.data.data);
    };
    validate();
  }, [values.key]);

  return (
    <>
      {values ? (
        <div className="email-response">
          <div>
            <img src={LogoQoin} alt="logo" className="logo-image" />
          </div>
          {responseData ? (
            <SuksesAktivasi data={responseData} />
          ) : (
            <SudahAktivasi />
          )}
        </div>
      ) : null}
      <div className="text-center login">
        <a href={process.env.REACT_APP_URL_LOGIN}>Back to Login</a>
      </div>
    </>
  );
}

export default EmailResponse;
